<template>
    <kurcc-data-table-page-template :key="key"
                                    :data-table-title="getDataTableTitle"
                                    :defaultItem="defaultItem"
                                    :delete-dialog-title="$vuetify.lang.t('$vuetify.pages.inspections.deleteDialogText')"
                                    :get-all-action-additional-params="prepareAdditionalParams"
                                    :headers="headers"
                                    :toolbar-button-dialog-title="$vuetify.lang.t('$vuetify.pages.inspections.newRandomInspection')"
                                    :toolbar-button-text="$vuetify.lang.t('$vuetify.pages.inspections.newRandomInspection')"
                                    :update-dialog-title="$vuetify.lang.t('$vuetify.pages.inspections.editInspection')"
                                    actions-suffix="Inspection"
                                    initial-sort-by-column="inspection_due_date"
                                    show-select>
        <template v-slot:dialog="{item}">
            <kurcc-inspections-dialog :item="item"/>
        </template>
    </kurcc-data-table-page-template>
</template>

<script>
import Inspection from "@/modules/inspections/models/inspection";

export default {
    name: "KurccInspectionsPage",
    components: {
        KurccDataTablePageTemplate: () => import('@/modules/app/templates/KurccDataTablePageTemplate'),
        KurccInspectionsDialog: () => import('@/modules/inspections/components/KurccInspectionsDialog'),
    },
    props: ['site_id', 'site', 'branch_id', 'branch', 'group_id', 'group'],
    data() {
        return {
            headers: [
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.inspectionSite'),
                    align: 'start',
                    value: 'site.name',
                    sortable: false
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.group'),
                    value: 'group_name',
                    sortable: false
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.dueDate'),
                    value: 'inspection_due_date'
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.inspector'),
                    value: 'inspector_name',
                    sortable: false
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.address'),
                    value: 'address',
                    sortable: false
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.deadline'),
                    value: 'deadline_days'
                },
                {text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.score'), value: 'score'},
                {text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.grade'), value: 'grade'},
                {text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.type'), value: 'type'},
                {text: this.$vuetify.lang.t('$vuetify.general.status'), value: 'status'},
                {text: this.$vuetify.lang.t('$vuetify.general.actions'), value: 'actions', sortable: false},
            ],
            defaultItem: new Inspection(),
            key: this.$dayjs().toISOString(),
            siteData: undefined,
            branchData: undefined,
            groupData: undefined
        }
    },
    computed: {
        prepareAdditionalParams() {
            let params = []
            if (this.site_id) params.push(`site_id=${this.site_id}`)
            if (this.branch_id) params.push(`branch_id=${this.branch_id}`)
            if (this.group_id) params.push(`group_id=${this.group_id}`)
            return params
        },
        getDataTableTitle() {
            if (this.siteData)
                return `${this.$vuetify.lang.t('$vuetify.pages.inspections.inspectionList')} (
                ${this.$vuetify.lang.t('$vuetify.general.site')}: ${this.siteData.name})`
            else if (this.branchData)
                return `${this.$vuetify.lang.t('$vuetify.pages.inspections.inspectionList')} (
                ${this.$vuetify.lang.t('$vuetify.general.branch')}: ${this.branchData.brand_name_en} #${this.branchData.number})`
            else if (this.groupData)
                return `${this.$vuetify.lang.t('$vuetify.pages.inspections.inspectionList')} (
                ${this.$vuetify.lang.t('$vuetify.general.group')}: ${this.groupData.name})`
            else return this.$vuetify.lang.t('$vuetify.pages.inspections.inspectionList')
        }
    },
    watch: {
        $route() {
            this.key = this.$dayjs().toISOString()
            this.fetchCurrentPageData()
        }
    },
    methods: {
        fetchSite() {
            this.$store.dispatch('getSite', {id: this.site_id})
                .then(res => {
                    this.siteData = res
                })
        },
        fetchBranch() {
            this.$store.dispatch('getBranch', {id: this.branch_id})
                .then(res => {
                    this.branchData = res
                })
        },
        fetchGroup() {
            this.$store.dispatch('getGroup', {id: this.group_id})
                .then(res => {
                    this.groupData = res
                })
        },
        fetchCurrentPageData() {
            if (this.site_id) {
                if (this.site)
                    this.siteData = this.site
                else this.fetchSite()
            } else this.siteData = undefined
            if (this.branch_id) {
                if (this.branch)
                    this.branchData = this.branch
                else this.fetchBranch()
            } else this.branchData = undefined
            if (this.group_id) {
                if (this.group)
                    this.groupData = this.group
                else this.fetchGroup()
            } else this.groupData = undefined
        }
    },
    created() {
        this.fetchCurrentPageData()
    }
}
</script>
